<template>
    <div class="boxVente" v-bind:style="fbackground()">    
        <vs-row>
            <vs-col vs-w="11">
                <h5 v-bind:style="fTxtColor()">{{ title }}</h5>
            </vs-col>
            <vs-col vs-w="1">
                <vs-icon icon="info" size="small" bg="primary"  color="white" round  @click="getInfo=true;"></vs-icon>
            </vs-col>
        </vs-row>

        <vs-divider />
        <h3 v-bind:style="fTxtColor()">{{ millier(devise.nb_decimal, (ht+totalTva).toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</h3>

        <vs-popup :title="'Détails : '+title" :active.sync="getInfo">
            
            <div class="titlenumber">
                <h3 class="pop mt-1 mb-3">{{ title }} HT : </h3>
                <h3 class="pop mt-1 mb-3 text-primary">{{ millier(devise.nb_decimal, ht) }} {{ devise.symbol }}</h3>
            </div>
            
            <vs-divider/>
            
            <!-- <h5 v-if="Object.keys(taxes).length > 0">Détail TVA :</h5> -->
            <ul v-if="Object.keys(taxes).length > 0" class="mt-5">
                <li v-for="(t, name, index) in taxes" :key="index">&bullet; TVA {{name}}% : <span class="text-primary">{{millier(devise.nb_decimal, t)}}{{ devise.symbol }}</span></li>
            </ul>
            <div class="titlenumber">
                <h3 class="pop mt-5 mb-3">Total TVA : </h3>
                <h3 class="pop mt-5 mb-3 text-primary">{{ millier(devise.nb_decimal, totalTva) }} {{ devise.symbol }}</h3>
            </div>

            <vs-divider/>

            <div class="titlenumber">
                <h3 class="pop my-3">{{ title }} TTC :</h3>
                <h3 class="pop my-3 text-primary">{{ millier(devise.nb_decimal, (ht+totalTva).toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</h3>
            </div>
        </vs-popup>
    </div>
</template>

<style lang="scss">
.boxVente {
    margin:10px;
    text-align:center;
    border-radius:5px;
    box-shadow: 0px 4px 25px 0px rgb(219, 219, 219);

    h5{
        padding:10px;
        height:35px;
    }
    h3{
        padding-bottom:10px;
        height:40px;
        text-align: center;
        padding-left: 15px;
    }
    i {
        margin: 10px 10px 10px -10px;
    }
    .vs-divider {
        margin-top: 0px;
    }
    .vs-icon-bg.bg-small {
        width: 1.04rem;
        height: 1rem;
        cursor: pointer;
    }
    .vs-icon.small {
        font-size: 1rem;
    }
}
.titlenumber {
    display: flex;
    justify-content: space-between;
}
.titlenumber h3, ul {
    padding: 0 10px;
}
li {
    font-weight: 500;
    line-height: 1.2;
    color: #2c2c2c;
    font-size: 1.1rem;
}
</style>

<script>
import Utils from '@/assets/utils/utils'

export default {
    props: {
        title:{
            type:String,
            default: '',
        },
        value:{
            type:String,
            default: '',
        },
        ht:{
            type:Number,
            default: null,
        },
        background:{
            type:String,
            default: '#fff',
        },
        textColor:{
            type:String,
            default:'black'
        },
        taxes: {
            type: Object,
            default() {
                return {}
            },
        }
    },
    data(){
        return{
            getInfo: false,
            devise: {}
        }
    },
    mounted() {
        this.$srvApi.Req('get', 'public/currency')
        .then(( rep )=>{
            this.devise = rep.data;
        })
    },
    computed: {
        totalTva() {
            let to = 0
            for (let t in this.taxes) to += this.taxes[t]
            return to
        },
    },
    watch:{
        $route (){
        }
    },
    methods:{
        millier:Utils.Millier,
        fbackground(){
            return {
                'background-color': this.background
            }
        },
        fTxtColor(){
            return {
                'color': this.textColor
            }
        }
    }
}
</script>