<template>

     <vx-card id="chart">
        <h1> Accessoires </h1>
         <div class="chart-wrapper">
            <apexchart 
            width="480" type="line" 
            :options="options" :series="series">
            </apexchart>
        </div>
        <!-- <button @click="updateChart">Update!</button> -->
     </vx-card>
</template>

<script>
import apexchart from 'vue-apexcharts'
import Item from '@/database/models/item'
export default {
    
    name:'facturationAccessGraph',
    components:{
        apexchart,
        Item,
    },
    props:[
        'listfact',
    ],
    data(){
        return {
            options: {
                chart: {
                    id: 'vuechart-example'
                },
               
                xaxis:{
                    categories:[]
                }
            },
            series: [{
                name: 'quantité',
                
                data:[],
            }],
        }
    },
    methods:{
        getData(list){
            // on revoit la liste des factures 
            let listName=[];
            let listQte=[];
            // console.log(" list compo : " , list, " taille : ", list.length);
            for (let i =0; i<list.length; i++){
                //console.log(" typeee //// ", list[i].type)

                // on verifie qu'il ne s'agit pas d'une facture, car sinon item = null 
                if (list[i].type !="Reçu"){
                    
                    // on recupere les données de l'item grâce à l'id de la facture 
                    Item.getTabAllMemoryKey( list[i].uid, function(items)
                    {
                        // pour l'instant on distingue pas accesoire et seance 
                        for (let j=0; j<items.length; j++){
                            // fitre accessoire 
                            if (items[j].credit==0){
                                // console.log(" credit ::: ", items[0].credit)
                                if (listName.includes(items[j].name)){

                                    //console.log( "existe !! ", items[0].name)
                                    let x = listName.indexOf(items[j].name)

                                    listQte[x]=listQte[x]+1;

                                }
                                else{
                                    //console.log( "existe pas ")
                                    listName.push(items[j].name);
                                    listQte.push(1);
                                }
                            }
                        }
                        
                    })
                    
                }
            }
            // console.log(" list name ::  ", listName, " list qté : ", listQte )
            let grandeList=[];
            // on met tout dan le data qu'utilisera apex 
            for (let i=0; i<listName.length; i++){
                let petiteList ={
                    x : listName[i],
                    y : listQte[i],
                }
                grandeList.push(petiteList);
            }
            //console.log( "voici la grande liste :: ", grandeList)
            this.series.data = listQte;
            this.options.xaxis.categories= listName;
            // console.log( "voici la grande liste :: ", this.series.data)
            // console.log(" list de depart :: ", this.series.data)
            // console.log(" list de depart :: ", this.options.xaxis.categories)
            this.updateChart(listQte);
            this.updateOption(listName);
        },
        updateChart(list ) {
        
        // In the same way, update the series option
        this.series = [{
          data: list
        }]
      },
      updateOption(list){
        //   console.log(" affiche ", list)
          this.options={
              xaxis:{
                  categories:list
              }
          }
      }

        
        
    }
            

}
</script>


<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>